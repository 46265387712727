.error-block {
    &__image {
        display: flex;
        flex: 1;
    }

    &_layout {
        &_tablet {
            .error-block__image {
                justify-content: center;

                & :first-child {
                    width: 235px;
                    height: 204px;
                }
            }
        }

        &_phone {
            .error-block__image {
                justify-content: center;

                & :first-child {
                    width: 168px;
                    height: 150px;
                }
            }
        }
    }
}
