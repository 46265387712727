.prisma-button.prisma-button_view_raised {
    color: var(--color-content-primary);
    background-color: var(--color-background-default);
    box-shadow: var(--shadow-small-default);

    &:hover {
        box-shadow: var(--shadow-small-hovered);
    }

    &:active {
        box-shadow: var(--shadow-small-pressed);
    }

    &:disabled {
        background-color: var(--color-background-glassy-a);
        color: var(--color-content-tertiary);
        box-shadow: var(--shadow-small-hidden);

        &.prisma-button_process {
            color: var(--color-content-primary);
            background-color: var(--color-background-default);
            box-shadow: var(--shadow-small-hovered);
        }
    }

    & .prisma-button__sublabel {
        color: var(--color-content-secondary);
    }
}
