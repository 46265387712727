.error-block {
    display: flex;

    max-width: 1100px;
    margin: auto;
    padding: 0 10px;

    &_layout {
        &_tablet,
        &_phone {
            flex-direction: column;
            align-items: center;

            padding: 0 16px;
        }
    }
}
