@import 'design/design';

.support-mode-section {
    height: 40px;

    &__bar {
        position: fixed;
        z-index: 10000;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: inherit;

        background: transparentize($colorError, 0.5);
    }

    &__logout {
        position: absolute;

        right: 20px;

        color: $colorPrimary;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
