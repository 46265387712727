@import 'prisma-button__dots.pcss';

@import '_view/prisma-button_view_primary.pcss';
@import '_view/prisma-button_view_secondary.pcss';
@import '_view/prisma-button_view_ghost.pcss';
@import '_view/prisma-button_view_clear.pcss';
@import '_view/prisma-button_view_raised.pcss';
@import '_view/prisma-button_view_link.pcss';

@import '_size/prisma-button_size_l.pcss';
@import '_size/prisma-button_size_m.pcss';
@import '_size/prisma-button_size_s.pcss';
@import '_size/prisma-button_size_xs.pcss';

@import '_position-in-group/prisma-button_position-in-group_left.pcss';
@import '_position-in-group/prisma-button_position-in-group_right.pcss';
@import '_position-in-group/prisma-button_position-in-group_middle.pcss';
@import '_behavior/prisma-button_behavior_common-link.pcss';
@import '_behavior/prisma-button_behavior_router-link.pcss';

.prisma-button {
    --border-width: 2px;
    --transition-duration: 0.15s;

    margin: 0;
    background: none;
    cursor: pointer;
    border: var(--border-width) solid transparent;
    display: inline-block;
    position: relative;
    transition-property: background-color, border-color, box-shadow, color, transform;
    transition-duration: var(--transition-duration);
    transition-timing-function: ease-out;

    &:disabled {
        cursor: default;
        pointer-events: none;
    }

    &:active {
        transform: scale(0.96);
    }
}

.prisma-button__content {
    display: flex;
    align-items: center;

    .prisma-button_process & {
        visibility: hidden;
    }
}

.prisma-button__text {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .prisma-button_icon-placement_right & {
        order: -1;
    }
}

.prisma-button__sublabel {
    transition-property: color;
    transition-duration: var(--transition-duration);
    transition-timing-function: ease-out;
}

.prisma-button__icon {
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;

    .prisma-button_icon-placement_left & {
        margin-right: var(--spacing-4);
    }

    .prisma-button_icon-placement_right & {
        margin-left: var(--spacing-4);
    }
}

.prisma-button.prisma-button_icon-placement_replace {
    /* Переменную --vertical-padding определяем в файлах ./_size/prisma-button_size_*.pcss */
    padding-left: var(--vertical-padding);
    padding-right: var(--vertical-padding);
}
