@import 'design/design';

:global(body) {
    margin: 0;
    padding: 0;
}

:root {
    --help-z-index: 100000;
}

#bundle-proficiency,
#bundle-profile {
    position: relative;

    background: $black;
}
