.error-block {
    &__description {
        overflow: hidden;

        transition: 0.2s all ease-in;

        font-family: 'YS Text';
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
    }

    &_layout {
        &_tablet,
        &_phone {
            .error-block__description {
                max-width: 416px;
                height: auto;
                margin: auto auto 18px;

                text-align: center;

                font-size: 13px;
                font-weight: normal;
                line-height: 17px;
            }
        }

        &_phone {
            .error-block__description {
                margin-bottom: 8px;

                line-height: 16px;
            }
        }
    }
}
