@import 'design/design';

.error-page {
    &__not-found-description {
        max-width: 530px;

        @media (max-width: $tabletScreen) {
            max-width: 416px;
        }
    }
}
