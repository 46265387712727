@import 'design/design';

body {
    margin: 0;
}

.error-page {
    display: flex;
    flex-direction: column;

    height: auto;
    min-height: 100vh;

    font-family: Suisse;

    &__header {
        display: flex;
        justify-content: space-between;

        padding: 20px 150px 0;

        @media (max-width: $tabletScreen) {
            display: none;
        }
    }

    &__footer {
        padding-bottom: 35px;

        color: transparentize($black, 0.8);

        font-size: 14px;
        line-height: 1.5em;
    }

    &__container {
        display: flex;
        flex: 1;
    }
}
