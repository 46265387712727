.prisma-button_size_xs {
    --vertical-padding: calc(7px - var(--border-width));

    font: var(--font-ys-caption-14-medium);
    padding: var(--vertical-padding) calc(12px - var(--border-width));
    border-radius: var(--border-radius-10);

    &.prisma-button_with-icon {
        --vertical-padding: calc(4px - var(--border-width));

        &.prisma-button_icon-placement_left {
            padding-left: calc(10px - var(--border-width));
            padding-right: calc(14px - var(--border-width));
        }

        &.prisma-button_icon-placement_right {
            padding-left: calc(14px - var(--border-width));
            padding-right: calc(10px - var(--border-width));
        }
    }
}
