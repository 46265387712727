@keyframes prisma-button__dots_flickering {
    from {
        opacity: 0.3;
    }

    20% {
        opacity: 0.5;
    }

    40% {
        opacity: 1;
    }

    to {
        opacity: 0.3;
    }
}

.prisma-button__dots {
    display: flex;
    gap: var(--spacing-4);
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;

    .prisma-button_process & {
        visibility: visible;
    }
}

.prisma-button__dot {
    width: 6px;
    height: 6px;
    background-color: currentColor;
    border-radius: 50%;
    flex-shrink: 0;
    animation: 1.5s ease-in infinite prisma-button__dots_flickering;

    &:nth-child(2) {
        animation-delay: 0.2s;
    }

    &:nth-child(3) {
        animation-delay: 0.4s;
    }
}
