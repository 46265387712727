/* в portal модалка рендерится ниже чем модалка со стр оплаты, поэтому поднимаем */
.verify-modal {
    &.verify-modal_visible {
        z-index: 3;
    }

    &__content-wrapper {
        display: flex;
    }
}
