// animation and classes for animations
@import './constants';

@mixin slideFadeTransition($duration, $offset: 20px, $easing: ease-out) {
    $transition: transform $duration $easing, opacity $duration $easing;

    &-enter {
        transform: translateY($offset);

        opacity: 0.01;
    }

    &-enter-active {
        transition: $transition;

        transform: none;

        opacity: 1;
    }

    &-exit {
        transform: none;

        opacity: 1;
    }

    &-exit-active {
        transition: $transition;

        transform: translateY($offset);

        opacity: 0.01;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
}

@keyframes fadeOut {
    to {
        opacity: 0;
    }
}

@keyframes rotation {
    to {
        transform: rotate(360deg);
    }
}

.fade {
    &-enter,
    &-appear {
        opacity: 0.01;
    }

    &-enter-active,
    &-enter-done,
    &-appear-active,
    &-appear-done {
        transition: all var(--fadeAnimationDelay, $fadeAnimationDelay) ease-out;

        opacity: 1;
    }

    &-exit {
        opacity: 1;
    }

    &-exit-active {
        transition: all var(--fadeAnimationDelay, $fadeAnimationDelay) ease-out;

        opacity: 0.01;
    }
}

.scale-fade {
    &-enter {
        transform: scale(0.01);

        opacity: 0;
    }

    &-enter-active {
        transition-timing-function: ease-out;
        transition-duration: $scaleFadeAnimationDuration;
        transition-property: opacity, transform;
        transform: scale(1);

        opacity: 1;
    }

    &-exit {
        transform: scale(1);

        opacity: 1;
    }

    &-exit-active {
        transition-timing-function: ease-out;
        transition-duration: $scaleFadeAnimationDuration;
        transition-property: opacity, transform;
        transform: scale(0.01);

        opacity: 0;
    }
}

.fade-slide-up {
    @include slideFadeTransition(0.2s, 15%);
}

.slide-right {
    &-enter,
    &-exit-active {
        transform: translateX(-100%);
    }
}

.slide-left {
    &-enter,
    &-exit-active {
        transform: translateX(100%);
    }
}

.slide-right,
.slide-left {
    $transition: transform 0.65s cubic-bezier(0.2, 1, 0.3, 1);

    &-enter,
    &-exit,
    &-enter-active,
    &-exit-active {
        will-change: transform;
    }

    &-enter-active {
        transition: $transition;
        transition-delay: 0.1s;
        transform: translateX(0);
    }

    &-exit {
        transform: translateX(0);
    }
}

@keyframes shake {
    12.5% {
        transform: translateX(12px);
    }

    25% {
        transform: translateX(0);
    }

    37.5% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(0);
    }

    62.5% {
        transform: translateX(3px);
    }

    75% {
        transform: translateX(0);
    }

    87.5% {
        transform: translateX(-2px);
    }

    100% {
        transform: translateX(0);
    }
}
