.error-block {
    &__subtitle {
        display: flex;

        align-items: center;

        padding-bottom: 15px;

        pointer-events: none;

        font-size: 20px;
        line-height: 23px;
    }

    &__subtitle-body {
        flex: 1;
    }

    &_layout {
        &_tablet,
        &_phone {
            .error-block__subtitle {
                padding-bottom: 4px;

                text-align: center;
                pointer-events: auto;

                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
            }
        }

        &_phone {
            .error-block__subtitle {
                line-height: 16px;
            }
        }
    }
}
