.prisma-button_size_m {
    --vertical-padding: calc(15px - var(--border-width));

    font: var(--font-ys-body-16-medium-ui);
    padding: var(--vertical-padding) calc(24px - var(--border-width));
    border-radius: var(--border-radius-12);

    &.prisma-button_with-icon {
        --vertical-padding: calc(13px - var(--border-width));

        &.prisma-button_icon-placement_left {
            padding-left: calc(18px - var(--border-width));
            padding-right: calc(26px - var(--border-width));
        }

        &.prisma-button_icon-placement_right {
            padding-left: calc(26px - var(--border-width));
            padding-right: calc(18px - var(--border-width));
        }
    }

    &.prisma-button_with-sublabel {
        padding: calc(7px - var(--border-width)) calc(24px - var(--border-width));
    }

    & .prisma-button__sublabel {
        font: var(--font-ys-caption-12-medium);
    }
}
