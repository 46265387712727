.prisma-button.prisma-button_view_ghost {
    border-color: var(--color-accent-neutral);
    color: var(--color-content-primary);

    &:hover {
        border-color: var(--color-accent-main-a);
        color: var(--color-accent-main-a);

        & .prisma-button__sublabel {
            color: var(--color-accent-main-a);
        }
    }

    &:active {
        border-color: var(--color-accent-main-b);
        color: var(--color-accent-main-b);

        & .prisma-button__sublabel {
            color: var(--color-accent-main-b);
        }
    }

    &:disabled {
        border-color: var(--color-background-glassy-a);
        color: var(--color-content-tertiary);

        &.prisma-button_process {
            border-color: var(--color-accent-main-a);
            color: var(--color-accent-main-a);
        }
    }

    & .prisma-button__sublabel {
        color: var(--color-content-secondary);
    }
}
