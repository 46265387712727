.prisma-button.prisma-button_view_link {
    --prisma-button-accent-color-resulting: var(--prisma-button-accent-color, var(--color-accent-main-a));
    --prisma-button-accent-color-active-resulting: var(--prisma-button-accent-color-active, var(--color-accent-main-b));

    color: var(--color-content-primary);

    &:hover {
        color: var(--prisma-button-accent-color-resulting);
    }

    &:active {
        color: var(--prisma-button-accent-color-active-resulting);
    }

    &:disabled {
        color: var(--color-content-tertiary);

        &.prisma-button_process {
            color: var(--prisma-button-accent-color-resulting);
        }
    }
}
