@import 'design/design';

.wizard-hint {
    &__tooltip-wrapper-appear {
        opacity: 0;
    }

    &__tooltip-wrapper-appear-active {
        transition: opacity 300ms;

        opacity: 1;
    }

    .wizard-hint {
        &__tooltip {
            &.wizard-hint__tooltip {
                padding: 10px 0 0;
            }
        }
    }

    &__tooltip {
        backdrop-filter: blur(10px);

        border: 1px solid transparentize($white, 0.7);

        font-size: 14px;
        line-height: 20px;

        &.tooltip-wizard.tooltip {
            padding-top: 16px;
        }
    }

    &__header {
        width: 260px;

        margin: 0 16px 8px;

        font-size: 16px;
        font-weight: bold;
    }

    &__content {
        width: 260px;

        margin: 0 16px;

        font-weight: 500;

        &:last-child {
            margin-bottom: 10px;
        }

        &:only-child {
            width: auto;
        }
    }

    &__footer {
        display: flex;

        width: 272px;
        justify-content: space-between;
        align-items: center;

        margin: 12px 6px 6px 16px;
    }

    &__pager {
        white-space: nowrap;

        font-size: 14px;
        font-weight: 500;

        &_one {
            visibility: hidden;

            pointer-events: none;
        }
    }

    &__button {
        & + & {
            margin-left: 2px;
        }

        &.wizard-hint__button_back.wizard-hint__button_back:hover {
            --button-background-color: rgba(255, 255, 255, 0.12);
        }

        &.wizard-hint__button_hidden {
            display: none;
        }

        &.wizard-hint__button_accent {
            --button-background-color: var(--profession-color);
        }

        &.wizard-hint__button_accent:hover {
            --button-background-color: var(--darken-profession-color);
        }
    }
}
