.error-block {
    &__title {
        margin-bottom: 48px;

        font-size: 40px;
        line-height: 48px;
    }

    &_layout {
        &_tablet,
        &_phone {
            .error-block__title {
                max-width: 416px;
                margin-bottom: 4px;

                text-align: center;

                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
            }
        }

        &_phone {
            .error-block__title {
                line-height: 16px;
            }
        }
    }
}
