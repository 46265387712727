.verify-notification {
    z-index: var(--z-index-verify-notification);
    width: 100%;
    background-color: var(--color-palette-yellow-normal);

    &_with-order-modal {
        position: fixed;
        top: 0;
    }

    &__content {
        margin: var(--spacing-20);

        text-align: center;
        font: var(--font-ys-body-16-medium-ui);
    }

    &__action-text {
        margin-left: var(--spacing-4);

        cursor: pointer;
        text-decoration-line: underline;
    }
}
