.verify-popup {
    margin: var(--spacing-20);
    position: relative;

    @media (--media-tablet-s) {
        margin-top: 100px;
    }

    &__title {
        margin: var(--spacing-4) 0 var(--spacing-8);

        font: var(--font-ys-header-20-bold);
    }

    &__content {
        margin-bottom: var(--spacing-16);

        font: var(--font-ys-body-16-medium-paragraph);
    }

    &__sender-button {
        outline: none;
        box-shadow: none;
    }

    &__close-icon {
        color: var(--color-palette-black-1000);
    }

    &__close-button.verify-popup__close-button {
        --button-size: 24px;

        height: var(--button-size);

        top: 0;
        left: calc(100% - var(--spacing-8) - var(--button-size) / 2);
        position: absolute;

        outline: none;
    }
}
