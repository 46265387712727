@import './colors';
@import '~skills-components/design/constants.scss';
@import './system.scss';

$tabletSmallScreen: 600px;
$phoneScreen: 666px;
$minPageWidth: 1024px;
$maxPageWidth: 1440px;

// fonts
$fontSizeSmall: 13px;
$fontSizeNormal: 14px;
$fontSizeLarge: 18px;

$lineHeightNormal: 22px;
$lineHeightLarge: 25px;

$lessonNavHeight: 60px;
$lessonControlsHeight: 60px;

$trainerHeaderHeight: 60px;
$trainerFooterHeight: 60px;
$trainerColumnHeight: calc(100vh - #{$trainerHeaderHeight});
$trainerTheoryPanelWidth: 44px;

$trainerThemeDark: dark;
$trainerThemeLight: light;

$codeBackgroundLight: transparentize($black, 0.94);
$codeBackgroundDark: $atomDark;
$codeForegroundLight: $atomBlack;
$codeForegroundDark: $atomWhite;
$colorCodeBorder: $veryLightGray;

$colorCodeStdOutLevelInfo: $crayonBlue;
$colorCodeStdOutLevelLog: $space;
$colorCodeStdOutLevelErr: $colorError;
$colorCodeStdOutLevelWarn: $lightGold;
$colorCodeStdOutLevelDebug: $raven;

$trainerRootTransitions: color 0.2s, background-color 0.1s;
$trainerBorderDark: 1px solid transparentize($white, 0.7);
$trainerBorderLight: #d8d8d8;
$trainerLessonMinHeight: 520px;

$fadeAnimationDelay: 250ms;
$scaleFadeAnimationDuration: 250ms;

$easingDefault: cubic-bezier(0.25, 0.1, 0.25, 1);

$professionColor: $pureBlue;
$professionColorDarken: #0166d8;
$professionColor: var(--profession-color, $pureBlue); // в IE проигнорируется
$professionColorDarken: var(--darken-profession-color, $professionColorDarken);

$initialVH: var(--initial-vh, 1vh);

// sidebar proficiency
$sbProfColor: $azure;

// z-index
$trainerNavigationPortalZindex: 1000000;

// border-radius
$helpItemsBorderRadius: 4px;
$courseItemsBorderRadius: 8px;
$profileCardsBorderRadius: 16px;
$profileContentBorderRadius: 24px;
