.prisma-button.prisma-button_view_secondary {
    background-color: var(--color-background-glassy-a);
    color: var(--color-content-primary);

    &:hover {
        background-color: var(--color-background-glassy-b);
    }

    &:active {
        background-color: var(--color-background-glassy-c);
    }

    &:disabled {
        background-color: var(--color-background-glassy-a);
        color: var(--color-content-tertiary);

        &.prisma-button_process {
            background-color: var(--color-background-glassy-b);
            color: var(--color-content-primary);
        }
    }

    & .prisma-button__sublabel {
        color: var(--color-content-secondary);
    }
}
