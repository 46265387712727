@import 'design/design';

.link {
    position: relative;

    transition: color 0.2s ease-out 0s;
    text-decoration: none;

    -moz-transition: none; // fallback for Firefox

    &_theme {
        &_normal {
            &.link_pseudo,
            &:link,
            &:visited {
                color: $pureBlue;
            }

            &.link_pseudo:hover,
            &:hover {
                transition: none;

                color: $vividRed;
            }
        }

        &_black {
            &.link_pseudo,
            &:link,
            &:visited {
                color: $black;
            }

            &.link_pseudo:hover,
            &:hover {
                transition: none;

                color: $amaranth;
            }
        }

        &_yellow {
            &.link_pseudo,
            &:link,
            &:visited {
                text-decoration: underline;

                color: $lightLemon;
            }

            &.link_pseudo:hover,
            &:hover {
                transition: none;

                color: $golden;
            }
        }

        &_on-dark {
            transition: none;

            &.link_pseudo,
            &:link,
            &:visited {
                color: $darkSideLink;
            }

            &.link_pseudo:hover,
            &:hover {
                transition: none;

                color: $amaranth;
            }
        }

        &_clean {
            transition: none;

            &.link_pseudo,
            &:link,
            &:visited,
            &:hover,
            &:active {
                text-decoration: underline;

                color: $black;
                outline: 0;
            }

            &:hover::after {
                transition: none;
            }

            &::after {
                display: none;
            }
        }

        &_abstract {
            &.link_pseudo,
            &:link,
            &:visited,
            &:hover,
            &:active {
                outline: 0;
            }

            &::after {
                display: none;
            }
        }

        &_ghost {
            &.link_pseudo,
            &:link,
            &:visited {
                color: $perlDarkGray;
            }

            &.link_pseudo:hover,
            &:hover {
                transition: none;

                color: $deepCarminePink;
            }
        }
    }

    &_disabled {
        cursor: default;

        &.link_pseudo,
        &:link,
        &:visited,
        &:hover,
        &:active {
            pointer-events: none;

            color: inherit;
        }
    }

    &_pseudo {
        cursor: pointer;
    }
}
