@import '~skills-components/design/figma-colors';

.error-block {
    &__link.link.link,
    &__link.link:link,
    &__link.link:visited {
        text-decoration: underline;

        color: $colorPrimary;
    }
}
