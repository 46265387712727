@import '../../design/design';

:root {
    --z-index-portals: 10000;
    --z-index-yandex-bug: calc(var(--z-index-portals) + 1);
    --z-index-wizard: calc(var(--z-index-portals) - 1);

    --z-index-profile-portals: calc(var(--z-index-portals) + 1);
    --z-index-profile-floating-button: calc(var(--z-index-profile-portals) - 1);

    --z-index-verify-notification: calc(var(--z-index-portals) + 1);

    /* Эта переменная нужна в мобильном тренажере для корректного выставления высоты экрана https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9 */
    /* Значение переменной меняется только в мобильном тренажере, во всех остальных местах она остается равной 100vh */
    --app-height: 100vh;
}

html {
    height: 100%;
}

html,
body {
    min-height: var(--app-height);
    margin: 0;
    padding: 0;

    /* disabling ios automatic font-size adjustment */
    -webkit-text-size-adjust: 100%;
}

body {
    display: flex;
    flex-direction: column;

    height: auto;
    min-height: 100%;

    font-family: $familyYandexSans;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;

    --profession-color: #{$pureBlue};
}

input,
select,
textarea,
button {
    font-family: inherit;
    font-size: inherit;
}

/* для < IE 11 */
input[hidden] {
    display: none;
}

h1,
h2,
h3,
h4 {
    font-weight: normal;

    line-height: 1.2em;
}

h1 {
    margin: 2em 0 0.5em;

    font-size: 2em;
}

h2 {
    margin: 1em 0 0.8em;

    font-size: 1.54em;
}

h3 {
    margin: 1.2em 0 1em;

    font-size: 1.4em;
}

h4 {
    margin: 1.2em 0 1em;

    font-size: 1.2em;
}

#wizard {
    position: absolute;
    z-index: var(--z-index-wizard);

    width: 0;
    height: 0;
}

#portals {
    position: absolute;
    z-index: var(--z-index-portals);

    width: 0;
    height: 0;
}

#yndxbug-button {
    position: fixed;
    left: 0;
    bottom: 10%;
    z-index: var(--z-index-yandex-bug);

    .YndxBug {
        bottom: 10%;
    }
}

#mount,
#bundle-index,
#bundle-landings-v2,
.app,
.page {
    display: flex;

    flex: 1 0 auto;
    flex-direction: column;

    min-height: var(--app-height);

    background-color: $white;

    &__content {
        width: 100%;
    }

    &__loading {
        position: fixed;

        z-index: 10000;

        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: $white;
    }

    &__loading-spin-container {
        display: flex;

        width: 100%;
        height: 100%;

        align-items: center;
        justify-content: center;
    }
}
