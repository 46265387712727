.error-block {
    &__section {
        margin-bottom: 40px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &_layout {
        &_tablet {
            .error-block__section {
                margin-bottom: 18px;
            }
        }

        &_phone {
            .error-block__section {
                margin-bottom: 8px;
            }
        }
    }
}
