.prisma-button.prisma-button_view_primary {
    --prisma-button-accent-color-resulting: var(--prisma-button-accent-color, var(--color-accent-main-a));
    --prisma-button-accent-color-active-resulting: var(--prisma-button-accent-color-active, var(--color-accent-main-b));

    background-color: var(--color-accent-neutral);
    color: var(--color-content-inverted-primary);

    &:hover {
        background-color: var(--prisma-button-accent-color-resulting);
    }

    &:active {
        background-color: var(--prisma-button-accent-color-active-resulting);
    }

    &:disabled {
        background-color: var(--color-background-glassy-a);
        color: var(--color-content-tertiary);

        &.prisma-button_process {
            background-color: var(--prisma-button-accent-color-resulting);
            color: var(--color-content-inverted-primary);
        }
    }

    & .prisma-button__sublabel {
        color: var(--color-content-inverted-secondary);
    }
}
